"use client";

import { useRouter } from "next/navigation";
import { Button } from "./ui/Button";
import { v4 as uuidv4 } from "uuid";
import { ArrowRight } from "lucide-react";

export function CtaButton() {
  const router = useRouter();
  return (
    <Button
      onClick={() => {
        const route = uuidv4();
        router.push(route);
      }}
      size="lg"
    >
      Freepic Downloader <ArrowRight className="ml-2" />
    </Button>
  );
}
