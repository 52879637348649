"use client";
import React, { createContext, useContext, useState } from "react";

type DownloadFormContextType = {
  isButtonEnabled: boolean;
  timer: number;
  startTimer: () => void;
  downloadUrl: string | null;
  setDownloadUrl: React.Dispatch<React.SetStateAction<null | string>>;
};

// Create the context
const DownloadFormContext = createContext<DownloadFormContextType>({
  isButtonEnabled: false,
  timer: 10,
  startTimer: () => {},
  downloadUrl: null,
  setDownloadUrl: () => {},
});

// Custom hook to use the context
export const useDownloadFormContext = () => {
  const context = useContext(DownloadFormContext);
  if (context === undefined)
    throw new Error(
      "useDownloadFormContext must be used inside a DownloadFormProvider"
    );

  return context;
};

// Provider component
export const DownloadFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [timer, setTimer] = useState(10);
  const [downloadUrl, setDownloadUrl] = useState<null | string>(null);

  const startTimer = () => {
    setTimer(10);
    setIsButtonEnabled(false);

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(interval);
          setIsButtonEnabled(true);
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <DownloadFormContext.Provider
      value={{
        isButtonEnabled,
        timer,
        startTimer,
        downloadUrl,
        setDownloadUrl,
      }}
    >
      {children}
    </DownloadFormContext.Provider>
  );
};
